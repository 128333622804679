// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyClUN3ccQvfo4-ax5A9m1X5T5vZC9qc03k',
    authDomain: 'monitoramentofl.firebaseapp.com',
    databaseURL: 'https://monitoramentofl.firebaseio.com',
    projectId: 'monitoramentofl',
    storageBucket: 'monitoramentofl.appspot.com',
    messagingSenderId: '39426185572',
    appId: '1:39426185572:web:da22b5b6c22c73a0738271',
    measurementId: 'G-2Z02P5B432'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
