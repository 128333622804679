import { Component, OnInit, ViewChild } from '@angular/core';

import { Platform, PopoverController, IonSegment } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SelectProjectsComponent } from './components/pop-overs/select-projects/select-projects.component';
import { AccountOptionsComponent } from './components/pop-overs/account-options/account-options.component';
import { AuthService } from './services/auth.service';
import { Router, NavigationExtras } from '@angular/router';
import { MapDataService } from './services/map-data/map-data.service';
import { SelectAnalysisComponent } from './components/pop-overs/select-analysis/select-analysis.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  redes = [];
  filteredRedes = [];
  showSearchResults = false;
  handlingInput;

  @ViewChild('navSegment', {static: true}) navSegment: IonSegment;
  isLoggedIn: boolean;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private popoverController: PopoverController,
    public router: Router,
    public authService: AuthService,
    public mapData: MapDataService
  ) {
    this.initializeApp();
    const route = this.router.url.match(/^\/[^\/]*/g)[0].substr(1);
    this.SegmentValue = (route === 'parc' || route === 'epv' || route === 'formar' || route === 'home') ? 'alunos-aprendem' : route;
    this.getMapData();
  }

  SegmentValue;
  get segmentValue() {
    const route = this.router.url.match(/^\/[^\/]*/g)[0].substr(1);
    this.SegmentValue = (route === 'parc' || route === 'epv' || route === 'formar' || route === 'home') ? 'alunos-aprendem' : route;
    return this.SegmentValue;
  }

  set segmentValue(value) {
    this.SegmentValue = value;
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openProjectsPopOver(ev) {
    this.presentProjectsPopOver(ev).then(result => {

    });
  }

  openAnalysisPopOver(ev) {
    this.presentAnalysisPopOver(ev).then(result => {

    });
  }

  openAccountOptionsPopOver(ev) {
    this.presentAccountOptionsPopOver(ev).then(result => {

    });
  }

  async presentProjectsPopOver(ev) {
    const popover = await this.popoverController.create({
      component: SelectProjectsComponent,
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(result => {
      if (result.data) {
        this.router.navigate([result.data]);
      } else {

      }
    });
    return await popover.present();
  }

  async presentAnalysisPopOver(ev) {
    const popover = await this.popoverController.create({
      component: SelectAnalysisComponent,
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(result => {
      if (result.data) {
        this.router.navigate([result.data]);
      } else {

      }
    });
    return await popover.present();
  }

  async presentAccountOptionsPopOver(ev) {
    const popover = await this.popoverController.create({
      component: AccountOptionsComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }


  async getMapData() {
    await this.mapData.getMunicipios();
    this.redes = this.mapData.statesData.map(el => {
      return {
        nome: el.nome,
        ibgeId: el.id,
        grupo: 'Estados'
      };
    });
    this.redes.push(...this.mapData.citiesData.map(el => {
      return {
        nome: el.nome,
        ibgeId: el.id,
        grupo: 'Cidades'
      };
    }));
  }

  handleInput(event) {
    if (this.redes === []) {
      if (this.handlingInput) {
        clearTimeout(this.handlingInput);
      }
      this.handlingInput = setTimeout(() => { this.handleInput(event); }, 100);
    }
    if (this.handlingInput) {
      clearTimeout(this.handlingInput);
    }
    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      if (query.length >= 2) {
        this.showSearchResults = true;
        this.filteredRedes = this.redes.filter(el => {
          return (el.nome.toLowerCase().indexOf(query) === 0 && query.length >= 2);
        });
      } else {
        this.showSearchResults = false;
      }
    });
  }

  navigateRede(ibgeId) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        ibgeId
      }
    };
    this.showSearchResults = false;
    this.router.navigate(['rede'], navigationExtras);
  }
}
