import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { INDICADORES } from '../../../constants/indicadores';

@Component({
  selector: 'app-metrics-options',
  templateUrl: './metrics-options.component.html',
  styleUrls: ['./metrics-options.component.scss'],
})
export class MetricsOptionsComponent implements OnInit {
  @Input() selected = [];
  options = INDICADORES;
  constructor(private popoverCtrl: PopoverController) {

  }

  ngOnInit() {}

  save(metric) {
    this.popoverCtrl.dismiss(this.selected);
  }

  valueChanged(ev, indicador) {
    if (ev.detail.checked) {
      this.selected.push(indicador);
    } else {
      const id = this.selected.findIndex(el => el.nome === indicador.nome);
      this.selected.splice(id, 1);
    }
  }
}
