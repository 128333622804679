import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SelectProjectsComponent } from './select-projects/select-projects.component';
import { SelectAnalysisComponent } from './select-analysis/select-analysis.component';
import { AccountOptionsComponent } from './account-options/account-options.component';
import { MetricsOptionsComponent } from './metrics-options/metrics-options.component';
import { SearchRedeComponent } from './search-rede/search-rede.component';
import { IndicadoresOptionsComponent } from './indicadores-options/indicadores-options.component';
import { SearchAreaComponent } from './search-area/search-area.component';

@NgModule({
  declarations: [
    SelectProjectsComponent,
    SelectAnalysisComponent,
    AccountOptionsComponent,
    MetricsOptionsComponent,
    SearchRedeComponent,
    IndicadoresOptionsComponent,
    SearchAreaComponent
  ],
  entryComponents: [
    SelectProjectsComponent,
    SelectAnalysisComponent,
    AccountOptionsComponent,
    MetricsOptionsComponent,
    SearchRedeComponent,
    IndicadoresOptionsComponent,
    SearchAreaComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    SelectProjectsComponent,
    SelectAnalysisComponent,
    AccountOptionsComponent,
    MetricsOptionsComponent,
    SearchRedeComponent,
    IndicadoresOptionsComponent,
    SearchAreaComponent
  ],
  providers: [],
  bootstrap: []
})
export class PopOversModule {}
