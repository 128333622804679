import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-select-analysis',
  templateUrl: './select-analysis.component.html',
  styleUrls: ['./select-analysis.component.scss'],
})
export class SelectAnalysisComponent implements OnInit {

  constructor(private popOverController: PopoverController) { }

  ngOnInit() {}

  dismiss(route) {
    this.popOverController.dismiss(route);
  }
}
